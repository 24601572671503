import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from './button'

const FeaturedColumns = data => {
  const dataColumnsCount = data.data.map((item, index) => (
    <FeaturedColumn key={index} item={item} columnsCount={data.data.length} />
  ))
  return dataColumnsCount
}

const FeaturedColumn = data => {
  return (
    <div
      className={
        'one-col feature-extended-image has-animation col-12 col-md-' +
        24 / data.columnsCount +
        ' col-lg-' +
        12 / data.columnsCount
      }
    >
      <div className={'bg-white shadow-md border-radius flex flex-col'}>
        <GatsbyImage
          image={getImage(data.item.heroImage)}
          style={{ width: '100%', height: '200px', objectFit: 'cover' }}
          alt={data.item.serviceTitle + ' Contractor in Columbus'}
        />
        <div className={'c-body card-center br-clr flex-1 flex flex-col justify-between'}>
          <div>
            <h4 className="color-dark">
              {data.item.featuredLabel
                ? data.item.featuredLabel
                : data.item.serviceTitle}
            </h4>
            <p className="short-excerpt">
              {data.item && data.item.shortExcerpt}
            </p>
          </div>
          <Button
            to={data.item && data.item.slug}
            color={'primary'}
            title={'Learn more about ' + data.item.serviceTitle}
          >
            Learn More
          </Button>
        </div>
      </div>
    </div>
  )
}

const FeaturedItems = ({ data }) => {
  return (
    <section className="featured-items">
      <div className="c-con container">
        <div className="c-r row">
          <FeaturedColumns data={data} />
        </div>
      </div>
    </section>
  )
}
export default FeaturedItems
